<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <vx-card title="الفلترة" class="mb-2" title-color="primary">

      <div class="vx-row  align-center">
        <div class="demo-alignment">
          <vs-popup class="holamundo" title="تنزيل المنتجات" :active.sync="popupActiveExport">
            <div class="flex flex-col justify-items-center mb-5">
              <div class="mb-2"><label for="">الملف</label></div>
              <file-upload ref="upload" v-model="file" class="vs-button bg-primary mt-5">
                رفع الملف
              </file-upload>
            </div>
            <vs-button @click="importNewProducts" color="success" type="filled" icon-pack="feather" icon="icon-check">
              تنزيل</vs-button>

          </vs-popup>
        </div>
        <div class="vx-col md:w-1/2 flex">
          <vs-dropdown vs-trigger-click class="md:w-1/2 dd-actions cursor-pointer mr-4 mb-4">
            <div class="
            p-4
            shadow-drop
            rounded-lg
            d-theme-dark-bg
            cursor-pointer
            flex
            items-center
            justify-center
            text-lg
            font-medium
            w-32 w-full
          ">
              <span class="mr-2">البحث ب {{ getSearchByKey(search_by) }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="search_by = 'starts_with[id]'">
                <span class="flex items-center">

                  <span>id المنتج</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="search_by = 'starts_with[sku]'">
                <span class="flex items-center">

                  <span>sku المنتج</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="search_by = 'starts_with[name]'">
                <span class="flex items-center">

                  <span>اسم المنتج</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-input placeholder="بحث" type="text" class="md:w-3/4" name="note" v-model="searchQuery" />
        </div>
      </div>
      <div class="vx-row  align-center mb-5">
        <div class="vx-col md:w-1/4">
          <div class="mb-2"><label for="">القسم الرئيسي</label></div>
          <v-select label="name" :reduce="(item) => item.id" v-model="category_id" @input="chooseCategory"
            :options="categories" name="main_catgeory" class="w-full"></v-select>
        </div>
        <div class="vx-col md:w-1/4">
          <div class="mb-2"><label for="">القسم الفرعي</label></div>
          <v-select label="name" :reduce="(item) => item.id" v-model="sub_category_id" :options="subcategories"
            name="main_catgeory" class="w-full"></v-select>
        </div>
        <div class="vx-col md:w-1/4">
          <div class="mb-2"><label for="">العلامة التجارية</label></div>
          <v-select label="name" :reduce="(item) => item.id" v-model="brand_id" :options="brands" name="brands"
            class="w-full"></v-select>
        </div>
        <div class="vx-col md:w-1/4">
          <div class="mb-2"><label for="">الحالة</label></div>
          <v-select label="title" :reduce="(item) => item.id" v-model="is_visible" :options="statuses" name="brands"
            class="w-full"></v-select>
        </div>
      </div>
      <div class="vx-row justify-end">
        <vs-button class="mr-3" color="#7367F0" type="filled" @click="search()">
          بحث
        </vs-button>
        <vs-button class="mr-3" color="warning" type="border" @click="reset()">
          إعادة تعيين
        </vs-button>
      </div>

    </vx-card>
    <div class="demo-alignment">
      <vs-popup class="holamundo" title="كتابة رقم ال sku" :active.sync="popupActive">
        <div class="flex flex-col justify-items-center">


          <label for="">رقم sku للمنتج المنسوخ</label>
          <vs-input type="text" class="w-full py-3" v-model="copy_sku" />
        </div>

        <vs-button @click="submitCopy()" color="success" type="filled" icon-pack="feather" icon="icon-check">
          نسخ</vs-button>

      </vs-popup>
    </div>

    <vs-table ref="table" multiple :sst="true" @sort="handleSort" v-model="selected" :max-items="metaData.per_page"
      :data="productsList">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center">
          <!-- ADD NEW -->
          <router-link
            class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
            to="/products/new">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">إضافة منتج</span>
          </router-link>

          <vs-button @click="exportProducts" color="primary" class="mr-2" type="filled" icon-pack="feather"
            icon="icon-download">
            تنزيل المنتجات</vs-button>
          <vs-button @click="popupActiveExport = true" color="primary" class="mr-2" type="filled" icon-pack="feather"
            icon="icon-download">
            رفع المنتجات</vs-button>
        </div>

        <!-- ITEMS PER PAGE -->
        <div class="flex">

          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              {{ metaData.from }} الى {{ metaData.to }}
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 30">
                <span>30</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 50">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 100">
                <span>100</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <template slot="thead">
        <vs-th>#id</vs-th>
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="brand_id">العلامة التجارية</vs-th>
        <vs-th sort-key="category_id">القسم الرئيسي</vs-th>
        <vs-th sort-key="sub_category_id">القسم الفرعي</vs-th>
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="price">السعر</vs-th>
        <vs-th sort-key="is_visible">الحالة</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.id }}#</p>
            </vs-td>
            <vs-td class="product-img-container flex justify-center items-center" v-if="tr.media.length">
              <img :src="tr.media[0].full_path" class="product-img" />
            </vs-td>


            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.brand.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.category.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.sub_category.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.sku }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{ tr.price }}LYD</p>
            </vs-td>

            <vs-td>
              <vs-switch val="html" v-model="tr.is_visible" @change="changeStatus(tr.id, tr.is_visible)">
                Html
              </vs-switch>
            </vs-td>

            <vs-td>


              <a @click="editData(tr)">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
              </a>
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                @click.stop="confirm(tr.id)" />
              <feather-icon icon="CopyIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2"
                @click="enterSKU(tr.id)" />

            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination class="mt-4" :total="metaData.last_page" v-model="current_Page" @change="getData(current_Page)">
    </vs-pagination>

  </div>
</template>

<script>
import moduleProduct from "@/store/products/moduleProduct.js";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import VueUploadComponent from 'vue-upload-component'
export default {
  components: {
    vSelect,
    'file-upload': VueUploadComponent
  },
  data() {
    return {
      category_id: '',
      selected: [],
      products: [],
      isMounted: false,
      brand_id: '',
      subcategories: [],
      sub_category_id: '',
      status: "",
      addNewDataSidebar: false,
      sidebarData: {},
      current_Page: 1,
      itemsPerPage: 10,
      lastPage: 0,
      product_id: "",
      totalItems: 0,
      file: [],
      popupActive: false,
      copy_sku: '',
      duplicated_product_id: '',
      popupActiveExport: false,
      searchQuery: '',
      is_archived: '',
      is_visible: '',
      url: process.env.VUE_APP_BASE_URL,
      statuses: [
        {
          id: 1,
          title: 'ظاهر',
        },
        {
          id: 0,
          title: 'مخفي',
        },
      ],
      params: {},
      search_by: 'starts_with[sku]'
    };
  },
  watch: {
    itemsPerPage(newVal) {
      this.params['per_page'] = newVal;
      this.getData(this.current_Page);
    }
  },
  computed: {
    ...mapGetters("product", ["productsList", "metaData"]),
    categories() {
      return this.$store.state.product.Categories;
    },
    brands() {
      return this.$store.state.product.brands;
    },
  },
  methods: {
    ...mapActions("product", ["fetchProducts","importProducts"]),
    chooseCategory() {
      this.categories.map(item => {
        if (item.id == this.category_id) {
          this.subcategories = item.sub_categories;
        }
      })
    },

    importNewProducts() {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("file", this.file[0].file);
      this.importProducts(bodyFormData)
        .then((response) => {
          this.popupActiveExport = false
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تعديل المنتج بنجاح",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getSearchByKey(value) {
      if (value == 'starts_with[sku]') return 'sku المنتج';
      if (value == 'starts_with[name]') return 'اسم المنتج';
      if (value == 'starts_with[id]') return 'id المنتج';
    },
    handleSort(key, active) {
      this.params[`sortby[key]`] = key;
      this.params[`sortby[active]`] = active;
      this.getData(this.current_Page);
    },
    reset() {
      this.params = {}
      this.getData(1)
    },
    search() {
      this.params[this.search_by] = this.searchQuery
      this.params['exact[category_id]'] = this.category_id
      this.params['exact[brand_id]'] = this.brand_id
      this.params['exact[sub_category_id]'] = this.sub_category_id
      this.params['exact[is_visible]'] = this.is_visible
      this.getData(1)
    },
    enterSKU(id) {
      this.duplicated_product_id = id
      this.popupActive = true;
    },
    toUrlQuery(params) {

      return `?${Object.keys(params).map(function (key) {

        return `${key}=${params[key]}`
      }).join('&')}`;

    },
    exportProducts() {
      this.params['is_archived'] = 0;
      this.params['type'] = 'allproducts';
      window.location = `${this.url}/api/products/export${this.toUrlQuery(this.params)}`;
    },
    exportUrl() {

    },
    submitCopy() {
      let formData = new FormData();
      formData.set("sku", this.copy_sku)
      formData.set("id", this.duplicated_product_id)
      this.$store
        .dispatch("product/duplicateProduct", formData)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم نسخ المنتج بنجاح",
            color: "success",
          });
          this.popupActive = false;
          this.getData();
        })
        .catch((error) => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },
    getData(page) {
      this.params['page'] = page
      this.fetchProducts(this.params).then(() => {
        this.$vs.loading.close();
      }).catch(() => {
        this.$vs.loading.close();

      })

    },
    editData(data) {
      this.$store.dispatch("product/editProduct", data);
      this.$router.push({
        name: "edit-product",
      });
    },
    confirm(id) {
      this.product_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل انت متأكد من مسح هذا المنتج؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },
    deleteData() {
      this.$store
        .dispatch("product/deleteProduct", this.product_id)
        .then(() => {
          this.getData();
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم مسح المنتج بنجاح",
            color: "success",
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },
    changeStatus(id, status) {
      var bodyFormData = new FormData();
      bodyFormData.set("id", id);
      bodyFormData.set("status", status ? 1 : 0);

      this.$store
        .dispatch("product/changeStatus", bodyFormData)
        .then(() => {
          this.getData();
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تغيير حالة المنتج بنجاح",
            color: "success",
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },
    archive() {
      var bodyFormData = new FormData();
      bodyFormData.set("id", this.selected.map(item => {
        return item.id
      }));
      bodyFormData.set("status", 1);

      this.$store
        .dispatch("product/archive", bodyFormData)
        .then(() => {
          this.getData();
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تغيير حالة المنتج بنجاح",
            color: "success",
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },
  },
  mounted() {
    this.$vs.loading();
  },
  created() {

    if (!moduleProduct.isRegistered) {
      this.$store.registerModule("product", moduleProduct);
      moduleProduct.isRegistered = true;
    }
    this.$store.dispatch("product/fetchCategories");
    this.$store.dispatch("product/fetchBrands");
    this.params['page'] = this.current_Page
    this.getData();
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.product-img-container {
            height: 110px;

            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 68px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
